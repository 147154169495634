<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardBody>
          <!-- ======= Pricing Section ======= -->
          <section id="pricing" class="pricing section-bg">
              <div class="container">
                  <div class="section-title">
                    <h2>{{ $t('Package') }}</h2>
                    <p>{{ $t('PackageDescription') }}</p>
                  </div>
                  <div class="row no-gutters">
                      <div class="col-lg-4 box"
                           v-for="(plan, index) in plans"
                           :key="'plan-'+plan.id"
                           :id="'plan-'+plan.id">
                          <h3>{{ plan.name }}</h3>
                          <h4>${{ plan.price.quantity }}<small v-if="plan.meta.SUPPORT_FEE!='-'">+</small><span>{{ $t('Monthly') }}</span></h4>
                          <ul role="list-items" class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              {{ $t('Containsthenumberofdomains') }}
                              <span class="badge badge-success badge-pill"> {{ plan.meta.DOMAIN }} </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              {{ $t('Upgradabledomainnamelimit') }}
                              <span class="badge badge-success badge-pill" v-if="plan.meta.DOMAIN_UP > plan.meta.DOMAIN">{{ $t('Upto', [ plan.meta.DOMAIN_UP ] ) }}</span>
                              <span class="badge badge-danger badge-pill" v-else>{{ $t('no') }}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"> {{ $t('CCtrafficattackprevention') }} <span class="badge badge-primary badge-pill"> {{ plan.meta.CC }}</span></li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"> {{ $t('DDOSattackprevention') }} <span class="badge badge-primary badge-pill"> {{ plan.meta.DDOS }}</span></li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"> {{ $t('Genericdomainname') }} <span class="badge badge-primary badge-pill"> {{ $t(plan.meta.WILDDOMAIN) }} </span></li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">{{ $t('Traffic') }} <span class="badge badge-primary badge-pill"> {{ plan.meta.TRAFFIC }} </span></li>
                            <!-- <li class="list-group-item d-flex justify-content-between align-items-center"> {{ $t('FreeTraffic') }} <span class="badge badge-primary badge-pill">{{ plan.meta.EXTRA_TRAFFIC }}</span></li> -->
                            <li class="list-group-item d-flex justify-content-between align-items-center"> IPV6 <span class="badge badge-pill" :class="{'badge-primary': plan.meta.IPV6 === 'yes', 'badge-danger': plan.meta.IPV6 === 'no'}"> {{ plan.meta.IPV6 }}</span></li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"> {{ $t('ServiceSupport') }} <span class="badge badge-primary badge-pill"> {{ plan.meta.SUPPORT }}</span></li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"> {{ $t('SupportFee') }} <span class="badge badge-success badge-pill"> {{ $t(plan.meta.SUPPORT_FEE) }}</span></li>
                          </ul>

                          <a class="btn-buy"
                             @click="clickPlan(plan)">
                            {{ $t('Select') }}
                          </a>
                      </div>

                  </div>

                  <div class="box">
                    <h3>{{ $t('Value-addedservices') }}</h3>
                      <ul role="list-items" class="list-group">
                          <li class="list-group-item d-flex align-items-center">
                            {{ $t('Accelerateddomainname') }} <span class="badge badge-info badge-pill"> {{ $t('Effectivemonthly') }} </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button data-shoppy-product="13rtGMV" class="btn badge badge-success badge-pill"> 1 / ${{ domain_price }}</button>&nbsp;&nbsp;
                          </li>
                          <li class="list-group-item d-flex align-items-center">
                            {{ $t('Highspeedandhighflowprevention') }} <span class="badge badge-info badge-pill"> {{ $t('Effectivemonthly') }} </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button data-shoppy-product="4tQ2kdN" class="btn badge badge-success badge-pill"> 100GB / ${{ traffic_price * 100 }}</button>&nbsp;&nbsp;
                          </li>
                      </ul>
                      <CRow class="text-left">
                        <CCol sm="6">
                        </CCol>
                        <CCol sm="6"
                              class="text-right price-wrapper">
                          <span id="price"><b>{{ $t('Price') }} </b> : $  {{ order.amountPrice }}</span>
                          <CButton size='lg'
                                   color='primary'
                                   @click="clickSubmit()">
                            {{ $t('Submit') }}
                          </CButton>
                        </CCol>
                      </CRow>
                  </div>
              </div>
          </section><!-- End Pricing Section -->
          <!-- ======= Contact Section ======= -->
          <section id="contact" class="contact">
              <div class="container">
                  <div class="section-title">
                    <h2>{{ $t('ContactConsulting') }}</h2>
                      <p></p>
                  </div>
                  <div class="row">
                      <div class="col-lg-12">
                          <div class="row">
                              <div class="col-md-4">
                                  <div class="info-box mt-4">
                                      <i class="bx bx-envelope"></i>
                                      <h3>Telegram</h3>
                                      <p><a href="https://t.me/ac_ludwig">Telegram:@ac_ludwig</a></p>
                                  </div>
                              </div>
                              <div class="col-md-4">
                                  <div class="info-box mt-4">
                                      <i class="bx bx-envelope"></i>
                                      <h3>Email</h3>
                                      <p>
                                          <a href="mailto:sales@asians.cloud">Email: sales@asians.cloud</a>
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section><!-- End Contact Section -->
          <!--<div class="embed-responsive embed-responsive-4by3" style="height:1600px">
            <iframe class="embed-responsive-item" :src="planSrc" ref="iframe" allowfullscreen></iframe>
          </div>-->
          <!-- <CModal :title="$t('checkout.CheckoutConfirmation')"
                  :show.sync="showCheckoutModal"
                  :closeOnBackdrop="false">
            <CRow>
              <CCol col="6">
                <label class="text-value">
                  {{ $t('checkout.CNAME') }}
                </label>
                <p>{{ checkout.cname }}</p>
              </CCol>

              <CCol col="6">
                <label class="text-value">
                  {{ $t('checkout.PlanName') }}
                </label>
                <p>{{ selectedPlan.name }}</p>
              </CCol>

              <CCol col="6">
                <label class="text-value">
                  {{ $t('checkout.AdditionalDomainNumber') }}
                </label>
                <p>{{ additionalDomainNumber }}</p>
              </CCol>

              <CCol col="6">
                <label class="text-value">
                  {{ $t('checkout.AdditionalTraffic') }}
                </label>
                <p>{{ checkout.additional_traffic }}</p>
              </CCol>

              <CCol col="6">
              </CCol>
              <CCol col="6">
                <label class="text-value">
                  {{ $t('checkout.TotalPrice') }}
                </label>
                <p>{{ checkout.amount_currency1 }} USDT</p>
              </CCol>

              <CCol col="12">
               <p>{{ $t('checkout.CheckoutConfirmation') }}</p>
              </CCol>
            </CRow>

            <template v-slot:footer>
              <button
                type="button"
                class="btn btn-primary"
                @click="clickConfirm()">
                {{ $t('Pay') }}
              </button>
            </template>
          </CModal> -->
          <PaymentCheckout :order="order" @hide="paymentShow=false" :paymentShow.sync="paymentShow" id="paymentcheckout"/>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import 'boxicons'
import 'boxicons/css/boxicons.min.css'
import 'remixicon/fonts/remixicon.css'

import axios from '@/plugins/axios'
import numeral from 'numeral';
import { listPlan } from '@/utilities/api'
import Price from '@/utilities/price'
import PaymentCheckout from '../payment/Checkout'


export default {
  name: "MemberPlans",
  components: {
    PaymentCheckout,
  },
  data() {
    return {
      order: {
        amountPrice: 0,
        type: "plan",
        plan: null,
        additional_domain_number: 0, 
        additional_traffic: 0
      },
      domain_price: Price.DOMAIN_PRICE,
      traffic_price: Price.TRAFFIC_PRICE,
      paymentShow: false,
      plans: [],
      showCheckoutModal: false,
      selectedPlan: null,
      selectedCoin: null,
      additionalDomainNumber: 0,
      additionalTrafficNumber: 0,
      isAdditionalModify: false,
      selectedPaymentType: null,
      checkout: {
        cname: null,
        additional_domain_number: 0,
        additional_traffic: 0,
        amount_currency1: 0,
        plan: {
          name: null
        }
      }
    };
  },
  mounted() {
    listPlan().then(response => {
      this.plans = response.data

      this.plans = this.plans.filter(function( obj ) {
        return obj.name !== "Prime" && obj.name !== "Booster";
      });
    }).catch((error) => {
      console.log(error)
    })
  },
  watch: {
    additionalDomainNumber() {
      this.order.amountPrice = 0;
      if (this.selectedPlan)  {
        this.order.amountPrice = this.selectedPlan.price.quantity;
        this.order.additional_domain_number = this.additionalDomainNumber;
        this.order.amountPrice += (this.additionalDomainNumber * Price.DOMAIN_PRICE);
        if (this.additionalTrafficNumber) {
          this.order.additional_traffic = this.additionalTrafficNumber + 'GB';
          const add_traffic = numeral(this.order.additional_traffic);
          const constanta = numeral('1GB');
          this.order.amountPrice += (add_traffic.value() / constanta.value()) * Price.TRAFFIC_PRICE;
        }
        this.order.amountPrice = this.order.amountPrice.toFixed(2);
      }
    },
    additionalTrafficNumber() {
      this.order.amountPrice = 0;
      if (this.selectedPlan) { 
        this.order.amountPrice = this.selectedPlan.price.quantity;
        if (this.additionalTrafficNumber != 0 && this.additionalTrafficNumber < 100) {
          this.isAdditionalModify = true;
          return;
        }
        this.isAdditionalModify = false;
        if (this.additionalTrafficNumber > 0) {
          this.order.additional_traffic = this.additionalTrafficNumber + 'GB';
        } else {
          this.order.additional_traffic = '0GB';
        }
        const add_traffic = numeral(this.order.additional_traffic);
        const constanta = numeral('1GB');
        this.order.amountPrice += (add_traffic.value() / constanta.value()) * Price.TRAFFIC_PRICE;
        if (this.additionalDomainNumber) {
          this.order.additional_domain_number = this.additionalDomainNumber;
          this.order.amountPrice += (this.additionalDomainNumber * Price.DOMAIN_PRICE);
        }
        this.order.amountPrice = this.order.amountPrice.toFixed(2);
      }
    }
  },
  methods: {
    clickSubmit() {
      if (this.selectedPlan) {
        this.order.plan = this.selectedPlan
        if (this.order.additional_traffic === 0) {
          this.order.additional_traffic = '0GB';
        }
        this.paymentShow = true
      } else {
        this.flash(this.$i18n.t('WarningPlanEmpty'), 'warning')
      }
    },

    clickTrial(plan) {
      axios.post('plan/subscription/trial/', {
        plan: plan.id
      }).then(response => {
        this.$router.push({name: 'DomainList'})
      }).catch(({errors}) => {
        errors.forEach(item => {
          this.flash(item, 'warning')
        })
      })
    },
    clickPlan(plan) {
      this.selectedPlan = plan
      this.order.amountPrice = plan.price.quantity
      this.additionalDomainNumber = 0
      this.additionalTrafficNumber = 0;
      document.querySelectorAll('[id*=plan-]').forEach(item => {
        item.classList.remove('Selected')
      })
      const el = document.querySelector(`#plan-${plan.id}`)
      el.classList.add('Selected')
    },
    clickContactConsultant() {
      const el = document.querySelector('#contact')
      window.scroll({
        top: el.offsetTop,
        left: 0,
        behavior: 'smooth'
      })
    },
  },
};
</script>
<style scoped>
/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}

.pricing .box {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  text-align: center;
}

.pricing h3 {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 28px;
}

.pricing h4 {
  font-size: 46px;
  color: #5a5af3;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 25px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 18px;
  display: block;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding-bottom: 12px;
}

.pricing ul i {
  color: #5a5af3;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
}

.pricing ul .na i {
  color: #ccc;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .btn-buy {
  background: #434175;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 50px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  transition: 0.3s;
  border: none;
      margin-top: 30px;
}

.pricing .btn-trial {
  background-color: #2982cc;
}

.pricing .btn-buy:hover {
  background: #5a5af3;
}

.pricing .featured {
  z-index: 10;
  margin: -30px -5px 0 -5px;
}

.pricing .featured .btn-buy {
  background: #5a5af3;
}

.pricing .featured .btn-buy:hover {
  background: #7272f5;
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg {
  background-color: #eeeef5;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #434175;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #5a5af3;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
  background: #fff;
}

.contact .info-box i {
  font-size: 32px;
  color: #5a5af3;
  border-radius: 50%;
  padding: 8px;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  background: #fff;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 5px;
}

.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
  border-color: #5a5af3;
}

.contact .php-email-form input {
  padding: 20px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #5a5af3;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #7272f5;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mt-10 {
  margin-top: 10px;
}

.btn-buy {
  cursor: pointer;
}

ul#coins {
  padding: 0;
  list-style: none;
}

ul#coins.Disabled li {
  border: unset !important;
  background-color: #eee;
}

ul#coins li {
  position: relative;
  display: inline-block;
  padding: 10px;
  margin: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  cursor: pointer;
}

ul#coins li.Active {
  border: 1px solid #0064bd;
}

ul#coins li div.box {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
}

ul#coins li img {
  width: 100px;
  height: 100px;
}

ul#coins p {
  margin: 0;
  text-align: center;
}

div.Selected {
  border: 2px solid blue;
}

div.price-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

span#price {
  font-size: 1.5em;
  display: block;
}
</style>
